import React from "react";
import { InfoEmail } from "./Emails";
import { SocialIcons } from "./SocialIcons";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__row">
          <img className="footer__logo" src={"/logo.svg"} />
          <div className="footer__row footer__socials">
            <SocialIcons />
            <div>
              <h4>Contact us</h4>
              <InfoEmail />
            </div>
          </div>
        </div>
        <div className="footer__row footer__info">
          <span>© Status100 Promotions {new Date().getFullYear()}</span>
          {/* <div>
            <a href="#TODO" target="_blank" rel="noreferrer">
              <span>Privacy Policy</span>
            </a>{" "}
            <span>•</span>{" "}
            <a href="#TODO" target="_blank" rel="noreferrer">
              <span>Terms & Conditions</span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
