import React from "react";

import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";
import IconTwitter from "../svgs/IconTwitter.svg";
import IconTiktok from "../svgs/IconTiktok.svg";
import IconSnapchat from "../svgs/IconSnapchat.svg";

export function SocialIcons({}) {
  return (
    <div className={`social-icons`}>
      <a
        href="https://www.facebook.com/Status100Promo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/status100pr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
      <a
        href="https://twitter.com/status100pr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTwitter />
      </a>
      <a
        href="http://tiktok.com/@status100pr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTiktok />
      </a>
      <a
        href="https://www.snapchat.com/add/djkojo?share_id=JMRDlyuNCNg&locale=en-GB/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconSnapchat />
      </a>
    </div>
  );
}
