import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useMediaQuery } from "hooks/useMediaQuery";
import { SocialIcons } from "./SocialIcons";
import IconMenu from "../svgs/IconMenu.svg";
import IconCross from "../svgs/IconCross.svg";
import ResizeObserver from "resize-observer-polyfill";

const links = [
  {
    to: "/",
    children: "Tickets",
  },
  // {
  //   to: "/events/",
  //   children: "Events",
  // },
  // {
  //   to: "/gallery/",
  //   children: "Gallery",
  // },
  {
    to: "/contact/",
    children: "Contact",
  },
  {
    to: "/join/",
    children: "Join the team",
  },
  {
    to: "/signup/",
    children: "Sign up",
  },
];

export function Header() {
  const { pathname } = useLocation();

  const isLarge = useMediaQuery("(min-width: 800px)");

  const [navOpen, setNavOpen] = useState(false);

  const navRef = useRef();
  const lineRef = useRef();

  const resizeLine = () => {
    if (navRef.current) {
      const activeLink = navRef.current.querySelector("a.active");
      const navRect = navRef.current.getBoundingClientRect();

      if (activeLink) {
        const linkRect = activeLink.getBoundingClientRect();

        lineRef.current.style.width = `${linkRect.width}px`;

        const offsetX = linkRect.x - navRect.x;

        lineRef.current.style.left = `${offsetX}px`;
      }
    }
  };

  useEffect(() => {
    resizeLine();
  }, [pathname]);

  useEffect(() => {
    if (isLarge && navRef.current) {
      let timeout;

      const ro = new ResizeObserver(() => {
        clearTimeout(timeout);
        timeout = setTimeout(resizeLine, 100);
      });

      ro.observe(navRef.current);

      return () => {
        clearTimeout(timeout);
        ro.disconnect();
      };
    }
  }, [isLarge]);

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (isLarge) {
      setNavOpen(false);
    }
  }, [isLarge]);

  return (
    <header className={`header  ${navOpen ? "header--open" : ""}`}>
      <div className="header__content">
        <Link to="/">
          <img className="header__logo" src={"/logo.svg"} />
        </Link>
        <div className="header__nav-wrapper">
          <nav ref={navRef} className="header__nav">
            {links.map((link) => (
              <Link key={link.to} to={link.to} activeClassName="active">
                {link.children}
              </Link>
            ))}
            <SocialIcons />
            <div ref={lineRef} className="header__nav-line" />
          </nav>
        </div>
        <button className="header-toggle" onClick={() => setNavOpen(!navOpen)}>
          {navOpen ? <IconCross /> : <IconMenu />}
        </button>
      </div>
    </header>
  );
}
